<template>
	<Layout>
		<Header />
		<section class="filter">
			<div class="filter__wrapper">
				<b-field label="Evaluación" label-position="on-border">
					<b-select class="ratings" :placeholder="rating ? rating.name : ''" @input="updateContests">
						<option v-for="r in ratings" :key="r.id" class="is-capitalized" :value="r.id" :selected="ratingId == r.id">{{ r.name }}</option>
					</b-select>
				</b-field>
			</div>
		</section>
		<Error v-if="errored" :icon="true" :back="true" />
		<Results v-if="!loading && !errored && contests.length <= 0" message="No se encontraron disputas" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="n in 4" :key="n" class="column is-12-desktop">
				<Placeholder />
			</div>
		</div>
		<transition-group v-if="!loading" name="filtering" class="filtering" tag="div">
			<b-collapse v-for="criteria in contests" :key="criteria.id" :open="isOpen == criteria.id" @open="isOpen = criteria.id">
				<template #trigger="props">
					<div class="card-header block-button" :class="{ opened : props.open }">
						<h3 class="title my-3 is-size-5">
							<span>{{ criteria.criteria }}</span>
							<small class="is-block is-light">{{ criteria.contests.length }} disputa abierta e {{ getTotalContestsDone(criteria.contests) }} terminado</small>
						</h3>
						<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
					</div>
				</template>
				<div class="columns is-multiline mb-4">
					<div v-for="c in criteria.contests" :key="c.id" class="column is-12-mobile is-6-tablet is-4-desktop">
						<article class="block pointer" :class="{ 'done' : c.contest.done }" @click="openContestation(c.rating_criteria_id)">
							<div class="block__content contest">
								<h3 class="block__name is-ellipsis">{{ c.contest.dealer.name }}</h3>
								<p>
									<span><strong>Puntuación:</strong> {{ c.contest.rating_criteria.value }} de {{ getMaxValueCriteria(c.contest.rating_criteria) }}</span>
								</p>
								<p class="block__bubble">
									<svg-icon class="icon" icon="messages" />
									<span class="points">{{ c.total_messages }}</span>
								</p>
								<p class="block__address">{{ format(c.contest.created_at) }} • {{ timeTo(c.contest.created_at) }}</p>
								<svg-icon v-if="c.contest.done" class="icon" icon="checked" />
								<div class="block__users">
									<b-tooltip v-for="user in c.users" :key="user.id" :label="user.full_name">
										<img :src="user.avatar" />
									</b-tooltip>
								</div>
							</div>
						</article>
					</div>
				</div>
			</b-collapse>
		</transition-group>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Icon from '@/components/Icon'
import Placeholder from '@/components/placeholders/Collapse'
import Api from '@/services/api'
import { responsive } from '@/mixins/responsive'
import { errorToast } from '@/mixins/toast'
import Chat from '@/components/modals/Chat'
import { ModalProgrammatic as Modal } from 'buefy'

export default {
	name: 'Contests',
	mixins: [responsive],
	components: {
		Layout,
		Header,
		Placeholder,
		Error,
		Results,
		'svg-icon': Icon
	},
	data() {
		return {
			ratingId: this.$route.params.id,
			ratings: [],
			rating: null,
			contests: [],
			errored: false,
			loading: true,
            isOpen: 0
		}
	},
    mounted() {
		this.findRatings()
    },
	methods: {
		findRatings() {
			Api.get('rating/findAll')
				.then(({ data }) => {
					this.ratings = data
					if (this.ratings.length > 0) {
						if (this.ratingId) {
							this.updateContests(this.ratingId)
						} else {
							this.updateContests(this.ratings[0].id)
						}
					}
				})
				.catch((err) => {
					console.log(err)
					this.errored = true
					errorToast('Ocurrió un error al buscar evaluaciones.')
				})
		},
        findContests() {
			if (this.rating) {
				this.contests = []
				this.loading = true
				Api.get('contest/findByDealerRateId/' + this.rating.id)
					.then(({ data }) => {
						this.contests = data
					})
					.catch((err) => {
						console.log(err)
						errorToast('Ocurrió un error al buscar disputas.')
					})
					.finally(() => {
						this.loading = false
					})
        	}
		},
		async updateContests(id) {
			this.rating = this.ratings.find((r) => r.id == id)
			this.findContests()
		},
		openContestation(id) {
			Modal.open({
				parent: this,
				component: Chat,
				scroll: 'keep',
				customClass: 'is-lg',
				trapFocus: true,
				props: { id: id, setDone: this.setDoneByModal }
			})
		},
		setDoneByModal(criteriaRatingId, criteriaOriginalId) {
			this.contests[criteriaOriginalId].contests.forEach((c) => {
				if (c.rating_criteria_id == criteriaRatingId) {
					c.contest.done = 1
				}
			})
		},
		getMaxValueCriteria(ratingCriteria) {
			let weight = ratingCriteria.weight;

			if ([1, 2].includes(ratingCriteria.equivalence)) {
				let equivalence = ratingCriteria.equivalences[ratingCriteria.equivalences.length - 1]
				weight = equivalence.index
			}

			return weight
		},
		getTotalContestsDone(contests) {
			let total = 0;
			contests.forEach((c) => {
				if (c.contest.done) ++total
			})
			return total
		}
	}
}
</script>
